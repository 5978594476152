"use client";

import { ArrowDownTrayIcon, BuildingOffice2Icon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { Button } from "@nextui-org/react";
import Image from "next/image";
import Link from "next/link";
import { useTranslations } from "next-intl";

type References = {
  name: string;
  date: string;
  role: string;
  technologies?: string[];
  responsibilities?: string[];
  description: string;
  href?:
    | string
    | {
        name: string;
        href: string;
      }[];
};

const references: References[] = [
  {
    name: "TMS - GSS",
    date: "11/2024 - 12/2024",
    role: "Senior Full Stack Developer",
    description: "references.tms_description",
    technologies: ["NextJS, Tailwind css, Redux, Formik + Yup, Strapi, GraphQL, PrismaJS, MySQL, NATS, Superset"],
    responsibilities: [
      "Developed dynamic report generation feature to make creating reports for different businesses easier and faster, without the need to update the source code.",
    ],
    href: "https://www.autotms.vn",
  },
  {
    name: "ROBOCON TRAINING - GSS (PARTNER OF NEC)",
    date: "10/2024 - 11/2024",
    role: "Senior Full Stack Developer",
    description: "references.robocon_training_description",
  },
  {
    name: "RESEARCH AND DEMO PROJECTS - GSS",
    date: "05/2024 - 10/2024",
    role: "Senior Full Stack Developer",
    description: "references.research_description",
  },
  {
    name: "CHEERMEDAL - GSS (PARTNER OF NEC)",
    date: "11/2023 - 05/2024",
    role: "Senior Full Stack Developer",
    technologies: [
      "ReactJS, Redux, Material-UI, Tailwind css, Formik + Yup, Jest, Aws (Lambda, API Gateway, S3, Dynamodb, etc.)",
    ],
    responsibilities: [
      "Conducted thorough code reviews for both front-end and back-end code to ensure high quality and adherence to best practices.",
      "Collaborated with developers to identify and resolve issues, ensuring code quality, consistency, and scalability.",
      "Ensured that coding standards and guidelines were followed throughout the project.",
      "Created documentation summarizing code review findings, including root causes of issues, to inform team members and prevent similar errors from recurring.",
    ],
    description: "references.cheermedal_description",
  },
  {
    name: "LLM - GSS (PARTNER OF NEC)",
    date: "02/2024 - 03/2024",
    role: "Mentor Front-end Developer",
    description: "references.llm_description",
  },
  {
    name: "TMS - GSS",
    date: "10/2023 - 11/2023",
    role: "Senior Full Stack Developer",
    technologies: ["NextJS, Tailwind css, Redux, Formik + Yup, Strapi, PrismaJS, MySQL, Firebase"],
    responsibilities: [
      "Designed the project structure and developed core features of the Transport Management System (TMS) to streamline operations in freight transport businesses.",
      "Conducted technical research to explore and implement innovative solutions that enhance system functionality and performance.",
      "Provided guidance and mentorship to team members, ensuring alignment with technical standards and project goals.",
      "Performed code reviews to maintain code quality, consistency, and adherence to best practices within the development team.",
    ],
    description: "references.tms_description",
    href: "https://www.autotms.vn",
  },
  {
    name: "SPARKLE AI - GSS",
    date: "06/2023 - 10/2023",
    role: "Senior Full Stack Developer",
    technologies: [
      "NextJS, Tailwind css, Formik + Yup, PrismaJS, MySQL, Firebase Storage, Clerk, Sentry, Stripe, Sendgrid (mail), OpenAI Developer Platform",
    ],
    responsibilities: [
      "Designed the project structure and developed new features within the Sparkle AI project to enhance AI tools for content evaluation and improvement based on user input.",
      "Collaborated with team members to brainstorm and develop features that meet user needs and improve functionality.",
      "Proposed and implemented enhancements to ensure features are user-friendly and effectively meet user expectations.",
      "Provided support and assistance to team members encountering challenges, fostering a collaborative team environment.",
    ],
    description: "references.sparkle_ai_description",
  },
  {
    name: "CHEERMEDAL - GSS (PARTNER OF NEC)",
    date: "12/2022 - 06/2023",
    role: "Front-end Developer",
    technologies: ["ReactJS, Redux, Material-UI, Formik + Yup, Jest, Aws (Lambda, API Gateway, S3, Dynamodb, etc.)"],
    responsibilities: [
      "Developed new features for the project based on detailed requirements outlined by Japanese clients.",
      "Conducted thorough testing and debugging to ensure the functionality and reliability of newly implemented features.",
    ],
    description: "references.cheermedal_description",
  },
  {
    name: "PINFALL - GSS (PARTNER OF VITALIFY)",
    date: "05/2023 - 06/2023",
    role: "Mobile Front-End Developer",
    description: "references.pinfall_description",
  },
  {
    name: "CANAS - GSS (PARTNER OF NEC)",
    date: "03/2022 - 12/2022",
    role: "Front-end Developer",
    technologies: ["ReactJS, Material-UI, Redux, Formik + Yup, Jest, Java Spring Boot, Mybatis, MySQL, Redis"],
    responsibilities: [
      "Developed new features according to functional specifications provided by Japanese clients to enhance hospital operations management.",
      "Collaborated closely with stakeholders to understand requirements and ensure feature development aligned with client needs.",
      "Conducted code reviews to maintain code quality, adherence to best practices, and consistency across the development team.",
    ],
    description: "references.canas_description",
  },
  {
    name: "Miroku Genius - GSS (Partner of Vitalify)",
    date: "08/2022 - 10/2022",
    role: "Senior Front-end Developer",
    description: "references.miroku_genius_description",
  },
  {
    name: "Wifibox - GSS",
    date: "07/2022 - 08/2022",
    role: "Technical Sub Leader",
    description: "references.wifibox_description2",
  },
  {
    name: "Nexco - GSS (Partner of NEC)",
    date: "01/2022 - 03/2022",
    role: "Mobile Front-end Developer",
    description: "references.nexco_description",
  },
  {
    name: "BZK - GSS (Partner of Vitalify)",
    date: "11/2021 - 01/2022",
    role: "Senior Front-end Developer",
    description: "references.bzk_description",
    href: "https://www.am-bition.jp/rac-tech/jidounyuryoku/",
  },
  {
    name: "Wifibox - GSS",
    date: "06/2021 - 11/2021",
    role: "Full Stack Developer",
    description: "references.wifibox_description",
  },
  {
    name: "Media Trust - GSS",
    date: "11/2020 - 06/2021",
    role: "Full Stack Developer",
    description: "references.media_trust_description",
    href: [
      {
        name: "Tokyo Zoo",
        href: "https://www.tokyo-zoo.net",
      },
      {
        name: "Mygate",
        href: "http://mygate.jp",
      },
      {
        name: "Aspire",
        href: "https://www.aspirejpn.co.jp",
      },
      {
        name: "Kindai",
        href: "https://kindai-sales.co.jp",
      },
    ],
  },
  {
    name: "JOT - GSS",
    date: "05/2020 - 11/2020",
    role: "Full Stack Developer",
    description: "references.jot_description",
    href: "https://www2.jotnw.or.jp",
  },
  {
    name: "VAMS - GSS",
    date: "09/2019 - 05/2020",
    role: "Full Stack Developer",
    description: "references.vams_description",
    href: "https://dinhgia.vietinbankamc.vn",
  },
];

export default function AboutMePage() {
  const t = useTranslations();

  return (
    <>
      <div>
        <div>
          <img
            alt={t("common.header_background")}
            src="/images/header-background.avif"
            className="h-32 w-full object-cover lg:h-48"
          />
        </div>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
            <div className="flex">
              <Image
                alt={t("common.avatar")}
                src="/images/avatar.jpg"
                width={128}
                height={128}
                className="size-24 rounded-full sm:size-32"
              />
            </div>
            <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div className="mt-6 min-w-0 flex-1 sm:hidden md:block">
                <h1 className="truncate text-2xl font-bold text-gray-900">{t("common.full_name")}</h1>
                <p className="text-base text-gray-500">Full Stack Developer</p>
              </div>

              <div className="mt-6 flex flex-row justify-stretch space-x-4">
                <Button
                  as={Link}
                  prefetch={false}
                  variant="bordered"
                  color="primary"
                  href="/cv/duong-thanh-trung-full-stack-developer-cv.pdf"
                  target="_blank"
                  className="!rounded-[4px] !border-[1px] !border-blue-700 !text-blue-700"
                >
                  <ArrowDownTrayIcon aria-hidden="true" className="mr-1.5 size-5 text-blue-700" />
                  <span>{t("common.download_cv")}</span>
                </Button>
                {/* <LocaleSwitcher /> */}
              </div>
            </div>
          </div>
          <div className="mt-6 hidden min-w-0 flex-1 sm:block md:hidden">
            <h1 className="truncate text-2xl font-bold text-gray-900">{t("common.full_name")}</h1>
          </div>
        </div>
      </div>

      <div className="mt-16 border-b border-gray-200 pb-5 sm:pb-0 lg:mt-20" />

      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
          <h2 className="text-2xl font-bold uppercase tracking-tight text-blue-700">Contact</h2>
          <p className="mt-4 text-justify text-base text-gray-500 lg:text-left">
            Looking for top-quality solutions at the best price? Let’s connect! Whether it’s a project idea or a simple
            question, I’m here to help you achieve your goals efficiently and affordably.
          </p>

          <dl className="mt-10 space-y-4 text-base text-gray-500">
            <div className="flex items-center gap-x-4">
              <dt className="flex-none">
                <span className="sr-only">Email</span>
                <EnvelopeIcon aria-hidden="true" className="h-7 w-6 text-gray-400" />
              </dt>
              <dd>
                <a href="mailto:dttrung.code@gmail.com" className="hover:text-gray-900">
                  dttrung.code@gmail.com
                </a>
              </dd>
            </div>
            <div className="flex items-center gap-x-4">
              <dt className="flex-none">
                <span className="sr-only">Telephone</span>
                <PhoneIcon aria-hidden="true" className="h-7 w-6 text-gray-400" />
              </dt>
              <dd>
                <Link href="tel: (+84) 384342451" className="hover:text-gray-900">
                  (+84) 384342451
                </Link>
              </dd>
            </div>
            <div className="flex items-center gap-x-4">
              <dt className="flex-none">
                <span className="sr-only">Address</span>
                <BuildingOffice2Icon aria-hidden="true" className="h-7 w-6 text-gray-400" />
              </dt>
              <dd>An Lac Ward, Binh Tan District, Ho Chi Minh City, Vietnam.</dd>
            </div>
          </dl>

          <h2 className="mt-10 text-2xl font-bold uppercase tracking-tight text-blue-700">{t("common.about_me")}</h2>
          <p className="mt-4 text-base text-gray-500">{t("common.education")}</p>

          <div className="mt-10 flex w-full flex-col items-center justify-center">
            <Image
              alt={t("common.software_engineering_can_tho_university")}
              src="/images/software-engineering-can-tho-university.jpg"
              width={500}
              height={500}
              className="w-full object-cover group-hover:opacity-75 lg:w-1/2"
            />
            <p className="mt-3 w-full text-justify text-sm text-gray-600 lg:mt-5 lg:w-1/2 lg:text-center">
              {t("common.my_thesis")}
            </p>
          </div>

          <p className="mt-4 text-justify text-base text-gray-500 lg:text-left">{t("common.professional_history")}</p>

          <div className="mt-10 flex w-full flex-col items-center justify-center">
            <Image
              alt={t("common.green_space_solution_nec_vietnam_partnership")}
              width={500}
              height={500}
              src="/images/green-space-solution-nec-vietnam-partnership.JPG"
              className="w-full items-center object-cover lg:w-1/2"
            />
            <p className="mt-3 w-full text-justify text-sm text-gray-600 lg:mt-5 lg:w-1/2 lg:text-center">
              {t("common.robocon_training")}
            </p>
          </div>

          <h2 className="mt-16 text-2xl font-bold uppercase tracking-tight text-blue-700">{t("references.title")}</h2>
          <p className="mt-4 text-base text-gray-500">{t("references.description")}</p>

          <ol className="relative mt-10 border-s border-gray-200 dark:border-gray-700">
            {references.map((reference, index) => (
              <li className="mb-10 ms-4" key={index}>
                <div className="absolute -start-1.5 mt-1.5 h-3 w-3 rounded-full border border-white bg-gray-200 dark:border-gray-900 dark:bg-gray-700" />
                <time className="mb-1 text-sm font-normal leading-none text-gray-500">{reference.date}</time>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{reference.name}</h3>
                <p className="text-base text-gray-500">{reference.role}</p>
                <p className="mb-4 mt-2 text-justify text-base font-normal text-gray-500 dark:text-gray-400">
                  {t(reference.description)}
                </p>

                {reference.technologies && (
                  <>
                    <h2 className="text-base text-gray-500">Technologies:</h2>
                    <ul role="list" className="mb-4 mt-2 list-disc space-y-2 pl-6 marker:text-gray-300">
                      {reference.technologies.map((technology, index) => (
                        <li key={index} className="text-justify text-base font-normal text-gray-500 dark:text-gray-400">
                          {technology}
                        </li>
                      ))}
                    </ul>
                  </>
                )}

                {reference.responsibilities && (
                  <>
                    <h2 className="text-base text-gray-500">Responsibilities:</h2>
                    <ul role="list" className="mb-4 mt-2 list-disc space-y-2 pl-6 marker:text-gray-300">
                      {reference.responsibilities.map((item, index) => (
                        <li key={index} className="text-justify text-base font-normal text-gray-500 dark:text-gray-400">
                          {item}
                        </li>
                      ))}
                    </ul>
                  </>
                )}

                {typeof reference.href === "string" ? (
                  <Link
                    className="flex items-center text-sm font-medium text-blue-700"
                    href={reference.href}
                    target="_blank"
                  >
                    <span className="relative">
                      {t("common.explore_projects")}
                      <span className="sr-only">{reference.name}</span>
                    </span>
                    <svg
                      className="relative ml-2.5 mt-px overflow-visible text-blue-700"
                      width="3"
                      height="6"
                      viewBox="0 0 3 6"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M0 0L3 3L0 6" />
                    </svg>
                  </Link>
                ) : reference.href ? (
                  <ul role="list" className="mb-4 mt-2 list-disc space-y-2 pl-6 marker:text-blue-700">
                    {reference.href.map((item, index) => (
                      <li key={index} className="text-justify text-sm font-medium text-blue-700">
                        <Link href={item.href} target="_blank">
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <></>
                )}
              </li>
            ))}
          </ol>
        </div>
      </div>
    </>
  );
}
